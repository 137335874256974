import './App.css';

import logo from './logo.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Judgement Live
        </p>
        <img src={logo} alt="logo" />
        <p>
          By- Abhishek Somani, Rama Devi Somani and Sandeep Kumar Somani
        </p>
      </header>
    </div>
  );
}

export default App;
